/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box, Text } from "@theme-ui/components"
import useSiteMetadata from "../hooks/use-site-metadata"
import ExternalLinksIcons from "./external-links"
import FooterBox from "./footer-box"

const Footer = () => {
  const { siteTitle } = useSiteMetadata()

  return (
    <FooterBox
      sx={{
        boxSizing: `border-box`,
        display: `flex`,
        justifyContent: `space-between`,
        color: `#2A2D34`,
        a: {
          color: `#2A2D34`,
        },
        flexDirection: [`column`, `column`, `row`],
        minHeight: `250px`,
        backgroundColor: `#F4E409`,
        padding: `60px`
      }}
    >
      <Box sx={{ width: `600px` }}>
        <Text sx={{ fontFamily: `Montserrat`, textTransform: `Uppercase`, fontSize: `12px`, letterSpacing: `2.4px`, fontWeight: `700`, pb: 4}}>About us</Text>
        <Text sx={{ fontFamily: `Open Sans`, fontSize: `14px`, lineHeight: `26px`, fontWeight: `400`, pb: 4}}>
        This is a blog run by Raavn, Dark Beauty, and whoever else gets involved over time ("We").
        The content on this website are personal opinions and statements of the author of each post.
        They might not necessarily reflect what We all believe. &copy; {new Date().getFullYear()} {siteTitle}. All rights reserved.</Text>
        <img src={`By.png`} />
      </Box>
      <Box>
        <ExternalLinksIcons />
      </Box>
    </FooterBox>
  )
}

export default Footer
