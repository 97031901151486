/** @jsx jsx */
import React from "react"
import { Global } from "@emotion/core"
import { Box, Container, jsx } from "theme-ui"
import SEO from "./seo"
import Footer from "./footer"
import CodeStyles from "../styles/code"
import SkipNavLink from "./skip-nav"

type LayoutProps = { children: React.ReactNode; className?: string }

const Layout = ({ children, className }: LayoutProps) => (
  <React.Fragment>
    <Global
      styles={(theme) => ({
        "*": {
          boxSizing: `inherit`,
        },
        html: {
          WebkitTextSizeAdjust: `100%`,
        },
        img: {
          borderStyle: `none`,
        },
        pre: {
          fontFamily: `monospace`,
          fontSize: `1em`,
        },
        header: {
          backgroundColor: `#2A2D34`,
          padding: `0`,
        },
        "#body-container": {
          maxWidth: `100%`,
          padding: `0`,
        },
        "[hidden]": {
          display: `none`,
        },
        "::selection": {
          backgroundColor: theme.colors.text,
          color: theme.colors.background,
        },
        "blockquote": {
          borderLeftColor: "#F4E409 !important",
          color: "#212121 !important",
        },
        a: {
          transition: `all 0.3s ease-in-out`,
        },
        ".author-heading": {
          fontFamily: `Montserrat`,
          textTransform: `uppercase`,
          fontSize: `1rem`,
        },
        ".author-name": {
          fontFamily: `Montserrat`,
          textTransform: `uppercase`,
          fontSize: `2rem`,
          lineHeight: `3rem`,
          paddingBottom: `30px`,
          letterSpacing: `2.4px`,
        },
        ".author-description": {
          fontFamily: `Open Sans`,
          fontWeight: 300,
          color: `#fff`,
          fontSize: `1.3rem`,
          lineHeight: `2.6rem`,
        },
        // scrolling animation
        ".center": {
          position: `absolute`,
          top: `50%`,
          left: `50%`,
          transform: `translate(-50%, -50%)`,
        },
        "#scroll": {
          animation: `animateHeight 1.4s linear 0.3s infinite`,
        },
        "#circle--shape": {
          animation: `animateShape 1.6s ease-in-out infinite`,
        },
        "@keyframes animateShape": {
          "50%": {
            "cy": `50`,
            "opacity": `0.1`,
          },
        },
      })}
    />
    <SEO />
    <SkipNavLink>Skip to content</SkipNavLink>
    <Container id="body-container">
      <Box id="skip-nav" sx={{ ...CodeStyles }} className={className}>
        {children}
      </Box>
      <Footer />
    </Container>
  </React.Fragment>
)

export default Layout
