/** @jsx jsx */
import React from "react"
import { jsx, Link as TLink } from "theme-ui"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faSoundcloud,
  faInstagram,
  faInstagramSquare
} from '@fortawesome/free-brands-svg-icons'
library.add(
  faInstagramSquare,
  faInstagram,
  faSoundcloud
)

const ExternalLinksIcons = () => {
  return (
    <React.Fragment>
        <div sx={{marginTop: `60px`}}>
            <TLink key='https://soundcloud.com/raavn' href='https://soundcloud.com/raavn' sx={{ paddingLeft: `65px`}}>
              <FontAwesomeIcon icon={['fab', 'soundcloud']} size="2x" />
            </TLink>
            <TLink key='https://instagram.com/raavn' href='https://instagram.com/raavn' sx={{ paddingLeft: `65px`}}>
              <FontAwesomeIcon icon={['fab', 'instagram']} size="2x" />
            </TLink>
        </div>
    </React.Fragment>
  )
}

export default ExternalLinksIcons